@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
@include foundation-flex-classes;
@include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
//@include foundation-forms;
//// @include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
@include foundation-callout;
//@include foundation-card;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//// @include foundation-progress-element;
//// @include foundation-meter-element;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
//@include foundation-visibility-classes;
//@include foundation-float-classes;
@include foundation-flex-classes;


@include motion-ui-transitions;
@include motion-ui-animations;


html, body {
  height: 100%;
}

body {

  background: #141E30; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #141E30 , #243B55); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #141E30 , #243B55); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}


header {
  height: 100%;

  display: flex;
  align-items: center;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.container{
  margin: 0 auto;
  text-align: center;


  .callout {

    padding: 5rem;
    @include mui-animation(fade(in, 1));
    animation-duration: 2000ms;
    animation-iteration-count: 1;
    animation-timing-function: ease-in-out;

  }
}

address {
  font-style: normal;
}

